<template>
  <div class="header0">
    <el-container class="main">
      <el-header class="mainHeader">
        <div class="header_left">
          <span class="header_title" />
        </div>
        <div class="header_right">
          <div class="avatar">
            <el-avatar
              src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              class="el-avatar"
            />
          </div>
          <div class="user">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                {{ user_name }}
                <i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="exit">退出</el-dropdown-item>
                <el-dropdown-item @click.native="goChangePw">修改密码</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="notice" @click="go_messages">
            <el-badge :value="$store.state.message_count" class="item" :hidden="$store.state.message_count<=0">
              <i class="el-icon-bell" />
            </el-badge>
          </div>
        </div>
      </el-header>
    </el-container>
  </div>
</template>
<script>
import { removeToken } from '@/utils/auth'
export default {
  data() {
    return {
      user_name:''
    }
  },
  created() {
    // this.user_name = this.$store.state.userInfo.user_name
    this.user_name = JSON.parse(localStorage.getItem('userInfo')).user_name

  },
  methods: {
    exit() {
      removeToken()
      window.localStorage.clear()
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    goChangePw() {
      this.$router.push({ path: '/changePassword'})
    },
    go_messages() {
      this.$router.push({ path: '/messages'})
    },
  },
}
</script>
<style lang="scss" scoped>
.header0 {
  flex: 1;
  background: transparent;
  margin-bottom: px(10);
  box-shadow: 0px 0px px(8) 0px rgba(63, 32, 135, 0.08);
  .main {
    height: px(60); // 头部组件高度
    .mainHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px px(40) 0px px(60);
      height: px(60);
      .header_left {
        .header_title {
          color: #3f2087;
          font-size: px(22);
          font-weight: 600;
        }
      }
      .header_right {
        display: flex;
        align-items: center;
        .search {
          margin-right: px(40);
          .el-icon-search {
            font-size: px(18);
            color: #1a1a1b;
          }
        }
        .notice {
          margin-left: px(60);
          .el-icon-bell {
            font-size: px(18);
            color: #1a1a1b;
          }
        }
        .avatar {
          margin-right: px(12);
          .el-avatar {
            height: px(30);
            width: px(30);
          }
        }
        .user {
          cursor: pointer;
          .el-dropdown-link {
            color: #1a1a1b;
            font-size: px(16);
            outline: none;
          }
        }
      }
    }
  }
}
</style>
