import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
// import store from '@/store';
import { getToken, removeToken } from '@/utils/auth';
import router from '../router/index'
import NProgress from 'nprogress'; // progress bar

NProgress.configure({ showSpinner: true }); // NProgress Configuration

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: process.env.VUE_APP_ENV === '/development' ?  process.env.VUE_APP_ENV : process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 60 * 6// request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (getToken()) {
      config.headers['Authorization'] = `${getToken()}`;
    }
    NProgress.start();
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    NProgress.done();
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data;
    NProgress.done();
    if (res.code === 200) {
      return res;
    } else if (res.code == 201) {
        if(res.msg !='无效的页码,当前页数已超过最大值!'){
            Message.error(res.msg ? res.msg : error.response.data.detail);
        }
      return Promise.reject(res);
    } else if (res.code == 301) {
      Message.error(res.msg ? res.msg : error.response.data.detail || 'Error');
      return Promise.reject(res);
    } else if (res.code == 3001) {
      Message.error(res.msg ? res.msg : error.response.data.detail || 'Error');
      return Promise.reject(res);
    }else if (res.code == 3002) {
      // Message.error(res.msg ? res.msg : error.response.data.detail || 'Error');
      router.push({ name: 'changePassword',query: {is_redirect: 2} })
    }else if (res.code == 401) {
      Message.error(res.msg || 'Error');
      router.push({ path: '/login' })
    } else {
      Message.error(res.msg || 'Error');
      return Promise.reject(res.data);
    }
  },
  error => {
    NProgress.done();
    if (error.response) {
      if (error.response.status == 401) {
        removeToken()
        return Promise.reject(
          new Error(error.response.data.message || 'Error')
        );
      } else {
        Message.error(error.response.statusText + ':' + error.response.status);
        return Promise.reject(error.response);
      }
    } else {
      console.log(error.toString());
      Message.error(error.toString().indexOf("timeout of") !== -1?"请求超时":error.toString());
      return Promise.reject(error);
    }
  }
);

export default service;
