<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getToken } from '@/utils/auth';
import {addMenu } from '@/router/menuMain.js';
import api from '@/api/message.js'

export default {
  data() {
   return {}
  },
  
  created() {
    /* 
      1、获取token是否存在
      2、接口请求路由信息，顺便判断token能否使用
      3、根据返回的信息加载路由或清空token缓存
      4、默认路由重定向到搜索页
    */
    let menuList = JSON.parse(localStorage.getItem('menuList'))
    if(getToken()&&menuList){
      addMenu(menuList);
      this.check_message_queueFun()
      setInterval(()=>{
        this.check_message_queueFun()
      },1000*60*5)
    }else {
      setTimeout(()=> {
        let routerList = this.$router.options.routes
        let nowPath = this.$router.currentRoute.path
        let tempPage = routerList.find( e=> e.path === nowPath)

        if(tempPage){
        }else {
          this.$router.push( {name: 'Login'} )
        }
      },500)
    }

    // this.check_message_queueFun()
		// setInterval(()=>{
		// 	this.check_message_queueFun()
		// },1000*60*5)
  },
  methods: {
    // 消息推送星标
    check_message_queueFun(){
      api.check_message_queue().then(res=>{
				this.$store.commit("domessage_count", res.data.message_count);
			})
    }
  }
  
}
</script>

<style lang="scss">
@media print {
  @page {
    size: auto;
    margin: 3mm;
  }
  body {
    height: auto;
  }
  #printMe {
    width: 100%;
    height: 100%;
  }
}
@import './assets/css/reset.scss';
@import '@/assets/css/global.scss';
.zone {
  padding: px(20);
}

html::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
::-webkit-scrollbar {
  width: 11px;
  height: 11px;
  border: none;
  background: #ddd !important;
}
::-webkit-scrollbar-track-piece {
  border: none;
  position: absolute;
  padding: 0;
  box-shadow: none;
  background-color: #ddd;
  border-radius: 1px;
}
::-webkit-scrollbar-thumb:vertical {
  background-color: #999;
  border-radius: 0px;
  border: none;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: #999;
  border-radius: 0px;
  border: none;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-button:vertical {
  display: none;
}
::-webkit-scrollbar-track:vertical {
  background-color: black;
}
::-webkit-scrollbar-track-piece {
  background: #fff;
}
::-webkit-scrollbar-thumb:vertical {
  background-color: rgba(50, 50, 50, 0.3);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:vertical:hover {
  background-color: #3b3b3b;
}
::-webkit-scrollbar-corner:vertical {
  background-color: #535353;
}
::-webkit-scrollbar-resizer:vertical {
  background-color: #ff6e00;
}
</style>
