import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

import VueRouter from "vue-router";
import Print from 'vue-print-nb'
import VueQuillEditor  from 'vue-quill-editor'
import 'quill/dist/quill.core.css' 
import 'quill/dist/quill.snow.css' 
import 'quill/dist/quill.bubble.css' 
import '@babel/polyfill'
import { VueJsonp } from 'vue-jsonp'
import '@/assets/js/dialogDrag.js'//全局引入v-dialogDrag自定义指令
import md5 from 'js-md5';
import animated from 'animate.css' // npm install animate.css --save安装，在引入
// import Print from '@/assets/js/print.js';
import htmlToPdf from '@/assets/js/htmlToPdf'
Vue.use(htmlToPdf)
Vue.prototype.$md5 = md5;


// Vue.use(Print);
Vue.use(VueJsonp);
Vue.use(VueQuillEditor);
Vue.use(VueRouter);
Vue.use(Print);
Vue.use(animated)

import * as tool from '@/assets/js/tool.js';
Vue.prototype.$tool = tool;
import {px} from '@/assets/js/adaption.js'
Vue.prototype.$px = px;

Date.prototype.Format = function (fmt) { // author: meizz
  var o = {
    "M+": this.getMonth() + 1, // 月份
    "d+": this.getDate(), // 日
    "h+": this.getHours(), // 小时
    "m+": this.getMinutes(), // 分
    "s+": this.getSeconds(), // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
    "S": this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
}

// Number.prototype.toFixed = function (n,isEnd){
//   // n为期望保留的位数，超过限定，报错！
//     if (n > 20 || n < 0) {
//       throw new RangeError('toFixed() digits argument must be between 0 and 20');
//     }
//     // 获取数字
//     const number = this;
//     // 如果是NaN,或者数字过大，直接返回'NaN'或者类似'1e+21'的科学计数法字符串
//     if (isNaN(number) || number >= Math.pow(10, 21)) {
//       return number.toString();
//     }
//     // 默认保留整数
//     if (typeof (n) == 'undefined' || n == 0) {
//       return (Math.round(number)).toString();
//     }
//     let result = number.toString();
//      result = parseFloat(result);
//      if(isEnd){
//        return result;
//      }
//     return  (Math.round((result + Number.EPSILON) * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n,true);
   
// }


// 设置事件总线
export const eventBus = new Vue()
// new Vue({
//   el: '#app',
//   render: h => h(App)
// })

Vue.config.productionTip = false
Vue.use(ElementUI)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {
  });
};
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
