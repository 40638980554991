import { start } from "nprogress";

export function filterTime(type, sData, eData) {
    let startData = '', endData = '';
    if (type == '年') {
        startData = sData;
        endData = eData;
    } else if (type == '月') {
        startData = sData.substring(0, 4) + '-' + sData.substring(4, 6);
        endData = eData.substring(0, 4) + '-' + eData.substring(4, 6);
    } else if (type == '日') {
        startData = sData.substring(0, 4) + '-' + sData.substring(4, 6) + '-' + sData.substring(6, 8);
        endData = eData.substring(0, 4) + '-' + eData.substring(4, 6) + '-' + eData.substring(6, 8);
    }
    return startData + '~' + endData
}

export function moneyFormatter(money, num) {
    /*
     * 参数说明：
     * money：要格式化的数字
     * num：保留几位小数
     * */
    num = num >= 0 && num <= 20 ? num : 2;
    money = money + '';
    var index = money.indexOf('.') + 1;
    if (index > 1 && money.substring(index, money.length).length > num) {
        money = money.substring(0, index + num);
    }
    money = parseFloat((money + '').replace(/[^\d.-]/g, '')).toFixed(num) + '';
    var l = money.split('.')[0].split('').reverse(), r = money.split('.')[1];
    var t = '', i;
    for (i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? ',' : '');
    }
	var getNumber = num == 0 ? t.split('').reverse().join('') : t.split('').reverse().join('') + '.' + r
	return getNumber;
}

// 对象判空
export function isEmptyObject (objet) {
    for(let key in objet){
        if(objet[key] == "") return false
    }
    return true;
}

// 根据时间确定返回情况
export function dateType(start_day,end_day) {
    
    start_day = start_day.toString()
    end_day = end_day.toString()
    if( start_day.length == 4 ) {
        start_day += '0101'
        end_day += '1230'
    }else if( start_day.length == 6 ) {
        start_day += '01'
        end_day += '30'
    }
    const startNum = Number(start_day.substr(2,2)) * 365 + Number(start_day.substr(4,2)) * 30 + Number(start_day.substr(6,2))
    const endNum = Number(end_day.substr(2,2)) * 365 + Number(end_day.substr(4,2)) * 30 + Number(end_day.substr(6,2))
	const temp = endNum-startNum

	if(temp<35){
		return 2
	}else if(temp<370){
		return 1
	}else{
		return 0
	}
}
