import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || null,
    cacheList: [],//缓存的组件的列表
    message_count: 0,
    searchBtnLoading: false,
  },
  mutations: {
    // 缓存某个组件
    cache(state, value) {
      if (!state.cacheList.includes(value.name)) {
        if (value.type == 1) {
          state.cacheList.push(value.name)
        } else {
          state.cacheList.splice(value.name, 1)
        }
      }
    },
    domessage_count(state, value){
			state.message_count = value;
		},
    dosearchBtnLoading(state, value){
			state.searchBtnLoading = value;
		},
  },
  actions: {},
  getters: {
    cacheList: (state) => { return state.cacheList }
  },
  modules: {},
})
