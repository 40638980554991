// 自适应表格列宽 str为prop tableData为表格数据数组 flexWidth为自定义宽度用来适应自己的表格
const innerWidth = window.innerWidth

export function flexColumnWidth(width,min_width=88) {
  let a = width/1920 *innerWidth<min_width? min_width: width/1920 *innerWidth
  return a+'px'
}


// 全局px函数，用于组件内px参数的调整
export function px(width,min_width=88) {
  let a = width/1920 *innerWidth<min_width? min_width: width/1920 *innerWidth
  return a+'px'
}
